<style scoped=""></style>

<template>
    <div>
        <div class="row">
            <div class="col-lg-12">
                <div class="panel panel-primary">
                    <div class="panel-body">
                        <h3 class="panel-title">
                            <inline-svg :src="navbarInfo.icon" class="panel-icon" alt="" /> {{ navbarInfo.name }}
                        </h3>

                        <Notification :data="noti" :key="k" v-for="(noti, k) in notification.price"></Notification>

                        <div class="table-responsive">
                            <table class="table table-bordered table-hover">
                                <thead>
                                    <tr>
                                        <th>Icon</th>
                                        <th>Dịch vụ</th>
                                        <th>Giá <span v-if="settings.discount_user > 0">( -{{ settings.discount_user }}% )</span></th>
                                        <th v-if="settings.discount_cob > 0">Giá CTV ( -{{ settings.discount_cob }}% )</th>
                                        <th v-if="settings.discount_agency > 0">Giá Đại Lý ( -{{ settings.discount_agency }}% )</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(x, k) in priceLists">
                                        <td>
                                            <img :src="require('@/assets/images/service/' + x.icon + '.png')" height="30" alt="">
                                        </td>
                                        <td v-html="x.name"></td>
                                        <td v-html="x.price" :class="[(user.role == 'user' || user.role == 'admin') ? 'b-info' : '']"></td>
                                        <td v-if="settings.discount_cob > 0" v-html="x.price_cob" :class="[user.role == 'cob' ? 'b-warning' : '']"></td>
                                        <td v-if="settings.discount_agency > 0" v-html="x.price_agency" :class="[user.role == 'agency' ? 'b-danger' : '']"></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['navbarInfo', 'settings', 'user', 'notification'],
        data () {
            return {
                priceLists: []
            }
        },
        created () {
            this.getPirce();
        },
        methods: {
            getPirce () {
                this.$http.post('service/prices').then(res => {
                    this.priceLists = res.body;
                });
            }
        }
    }
</script>